// export default {
//   '401': '认证失败，无法访问系统资源',
//   '403': '当前操作没有权限',
//   '404': '访问资源不存在',
//   'default': '系统未知错误，请反馈给管理员'
// }

// 考虑到中英文双语环境，提示信息应以英文为主
export default {
    '401': 'Authentication failed, the system resources cannot be accessed',
    '403': 'The current operation has no permission',
    '404': 'The access resource does not exist',
    'default': 'The system does not know the error, please report the error to the administrator'
}
