<template>
  <div class="icon-svg" style="display:inline">
    <span>
        <img :src="imageUrl" alt=""  height="20px" style="height:15px;margin-left:20px;margin-right:5px;"/>
    </span>
  </div>
</template>
<script>

import languageSvg from './icon/language.svg'

export default {
  data(){
    return{
        imageUrl:languageSvg
    }
  },
  components:{}
}
</script>
