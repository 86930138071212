import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import ElementUI from 'element-ui'
//y230518 move to end,enhance effort
// import 'element-ui/lib/theme-chalk/index.css';
// import './style/public.scss'
import publicjs from "@/util/public"

//界面国际化，i18n，york 230223
import i18n from './common/i18n'
import directive from './directive' // directive

Vue.use(publicjs)
Vue.use(ElementUI)
// import ECharts from 'vue-echarts'
// Vue.component('v-chart', ECharts)
Vue.config.productionTip = false
// york230322
Vue.use(directive)

//y230517 add function from yudao
import { getDicts } from "@/api/system/dict/data";
import { DICT_TYPE, getDictDataLabel, getDictDatas, getDictDatas2 } from "@/utils/dict";
import store from './store'
// 自定义表格工具扩展
import RightToolbar from "@/components/components/RightToolbar"
import Pagination from "@/components/components/Pagination";
// import { parseTime, resetForm, handleTree, addBeginAndEndTime, divide } from "@/utils/ruoyi";
import { parseTime,resetForm,handleTree } from "@/utils/ruoyi";
// 字典标签组件
import DictTag from '@/components/components/DictTag'
//各种插件一次性导入
import plugins from './plugins' // plugins
//icon import for ready y230519
import '@/assets/icons' // icon
import DocAlert from '@/components/components/DocAlert'
//y230522 permission moudle, load menus
import './permission' // permission control
//y230916 video model
import videojs from 'video.js'


// 全局方法挂载
Vue.prototype.getDicts = getDicts
Vue.prototype.getDictDatas = getDictDatas
Vue.prototype.getDictDatas2 = getDictDatas2
Vue.prototype.getDictDataLabel = getDictDataLabel
Vue.prototype.DICT_TYPE = DICT_TYPE
Vue.prototype.parseTime = parseTime
Vue.prototype.resetForm = resetForm
Vue.prototype.handleTree = handleTree
//y230915
Vue.prototype.$videoJS = videojs

Vue.component('RightToolbar', RightToolbar)
Vue.component('Pagination', Pagination)
Vue.component('DictTag', DictTag)
Vue.component('DocAlert', DocAlert)
Vue.use(plugins)

//y230518 平台个性化设置
// 默认点击背景不关闭弹窗
ElementUI.Dialog.props.closeOnClickModal.default = false

//y230518 引入样式
import './assets/styles/element-variables.scss'
import '@/assets/styles/index.scss' // global css
import '@/assets/styles/ruoyi.scss' // ruoyi css
import '@/styles/index.scss'
//y230518 原有样式
import 'element-ui/lib/theme-chalk/index.css';
import './style/public.scss'
//y230518 新增全局样式
import '@/styles/mystyle_230518.scss'

new Vue({
  el: '#app',
  i18n, //注入配置好的i18n国际化插件，230222
    router,
  store, //y230517 增加存储功能
  render: h => h(App)
}).$mount('#app')