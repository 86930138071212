import axios from 'axios'
import { getToken, getTenantCode } from '@/util/auth'
import { Message } from 'element-ui'
import router from '@/router/index'
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000000 // request timeout
})
//在https中都是首字母大写显示，且在检测中不区分大小写
const requestFn = config => {
  if(getToken()){
    config.headers['Authorization'] = getToken()
    }
    // update y240314
    if (getTenantCode()){
        config.headers['Tenantcode'] = getTenantCode();
    } else { 
        //添加请求头，来区分用户类型york230422  
        config.headers['tenantcode'] = 'saas'
    }
  return config
}
const requestErrorFn = error => {
  return Promise.reject(error)
}
  
service.interceptors.request.use(requestFn,requestErrorFn)

const responseFn = response=>{
    let res = response.data
    if(res.code != 200 && res.code != 555 ){
        // Message({type:"error",message:res.msg || 'Error'})
        if (localStorage.getItem('languageSet') == 'zh-CN') {
            Message({ type: "error", message: res.msg || 'Error' })
        } else { 
            Message({ type: "error", message: res.egMsg || 'Error' })
        }
        return Promise.reject(new Error(res.egMsg || 'Error'))
    }else if(res.code == 555 ) { 
        //如果返回为555 表示登录的时候出现多用户，需要用户进行选择 y240331
        return res;
    } else {
        //返回200 表示数据正常
        return res;
    }
}
const responseErrorFn = responseError=>{
    // Message({ type: "error", message: responseError.message || 'Error' })
    console.log({ type: "error", message: responseError.message || 'Error' })
    //当发生请求错误时，放过请求，y230629
//   router.push({path:"/login"})
//   router.push({path:"/login"})
  return Promise.reject(responseError)
}
service.interceptors.response.use(responseFn,responseErrorFn)
  
const serviceopen = axios.create({
  baseURL: process.env.VUE_APP_OPEN_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 50000 // request timeout
})

const serviceBlob = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000000, // request timeout
  responseType: 'blob'
})
serviceBlob.interceptors.request.use(requestFn,requestErrorFn)
export {service as request,serviceopen as requestopen,serviceBlob as requestBlob}