// import Cookies from 'js-cookie'

// const TokenKey = 'AIScene-token'

// export function getToken() {
//   return Cookies.get(TokenKey)
// }

// export function setToken(token) {
//   return Cookies.set(TokenKey, token)
// }

// export function removeToken() {
//   return Cookies.remove(TokenKey)
// }

export function getToken() {
  return localStorage.getItem("AIScene-token")
}

export function setToken(token,username) {
  localStorage.setItem("AIScene-token",token)
  if(username){
    localStorage.setItem("AIScene-username",username)
  }
}

export function removeToken() {
  localStorage.removeItem("AIScene-token")
}

export function getTenantType() {
    return localStorage.getItem("AIScene-tenantType")
}

export function setTenantType(tenantType) {
    localStorage.setItem("AIScene-tenantType", tenantType)
}

//y230627
export function getTenantLogin() {
    return localStorage.getItem("AIScene-tenantLogin")
}

export function setTenantLogin(tenantLogin) {
    localStorage.setItem("AIScene-tenantLogin", tenantLogin)
}

export function getTenantCode() {
    return localStorage.getItem("AIScene-tenantCode")
}

export function setTenantCode(tenantCode) {
    localStorage.setItem("AIScene-tenantCode", tenantCode)
}

export function getUsername() {
    return localStorage.getItem("SLCP-username")
}

export function setUsername(tenantType) {
    localStorage.setItem("SLCP-username", tenantType)
}

export function getUserInfoData() {
    return localStorage.getItem("cmc-user-info")
}

export function setUserInfoData(userInfoData) {
    localStorage.setItem("cmc-user-info", userInfoData)
}