import {request,requestopen,requestBlob} from '@/request/axios'
import {setToken,removeToken} from '@/util/auth'
import { getTenantType, setTenantType, getTenantLogin, setTenantLogin, getTenantCode, setTenantCode, getUsername, setUsername, setUserInfoData, getUserInfoData } from './auth';
export default {
    install:function(Vue){
        Vue.prototype.$load = function(){
            return this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            
        }
        Vue.prototype.$tip = function(type,message){
            this.$message({type:type,message:message})
        }
        Vue.prototype.$confirmTip = function(title,confirm){
            this.$confirm(title, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                if(confirm){
                    confirm()
                }
              })
        }
        Vue.prototype.$post = function(url,data,headers,progress){
            return request.post(url,data,{
                headers:{
                    'Content-Type':headers?'application/x-www-form-urlencoded':'application/json'
                },onUploadProgress: progressEvent => {
                    let complete = (progressEvent.loaded / progressEvent.total * 100 | 0)
                    if(progress){
                        progress(complete)
                    }
                  }
            })
        }
        Vue.prototype.$get = function(url,data){
            return request.get(url,{
                params:data
            })
        }
        Vue.prototype.$put = function(url,data){
            return request.put(url,data)
        }
        Vue.prototype.$delete = function(url,data){
            return request.delete(url,data)
        }
        Vue.prototype.$postopen = function(url,data){
            return requestopen.post(url,data)
        }
        Vue.prototype.$getopen = function(url,data){
            return requestopen.get(url,{
                params:data
            })
        }
        Vue.prototype.$setToken = function(token,username){
            setToken(token,username)
        }
        Vue.prototype.$setTenantType = function (tenantType) {
            setTenantType(tenantType)
        }
        Vue.prototype.$getTenantType = function () {
            return getTenantType()
        }
        Vue.prototype.$setTenantLogin = function (tenantLogin) {
            setTenantLogin(tenantLogin)
        }
        Vue.prototype.$getTenantLogin = function () {
            return getTenantLogin()
        }
        Vue.prototype.$setTenantCode = function (tenantCode) {
            setTenantCode(tenantCode)
        }
        Vue.prototype.$getTenantCode = function () {
            return getTenantCode()
        }
        Vue.prototype.$setUsername= function (tenantType) {
            setUsername(tenantType)
        }
        Vue.prototype.$getUsername = function () {
            return getUsername()
        }
        //y230919
        Vue.prototype.$setUserInfoData = function (userInfoData) {
            setUserInfoData(userInfoData)
        }
        Vue.prototype.$getUserInfoData = function () {
            return getUserInfoData()
        }

        Vue.prototype.$removeToken = function(){
            removeToken()
        }
        Vue.prototype.$getBlob = function(url,data){
            return requestBlob.get(url,{
                params:data
            })
        }
        Vue.prototype.$getTime = function(time){
            return new Date(time).getFullYear() + '-' + (new Date(time).getMonth() + 1 < 10 ? '0' + (new Date(time).getMonth() + 1) : new Date(time).getMonth() + 1) + '-' + (new Date(time).getDate() < 10 ? '0' + (new Date(time).getDate()) : new Date(time).getDate())
        }
    }
}