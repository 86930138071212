<template>
  <div class="navbar">
    <hamburger id="hamburger-container" :is-active="sidebar.opened" class="hamburger-container" @toggleClick="toggleSideBar" />

    <breadcrumb id="breadcrumb-container" class="breadcrumb-container" v-if="!topNav"/>
    <top-nav id="topmenu-container" class="topmenu-container" v-if="topNav"/>

    <div class="right-menu">
      <template v-if="device!=='mobile'">
        <!-- <search id="header-search" class="right-menu-item" /> -->

        <!-- 站内信 -->
        <!-- <notify-message class="right-menu-item hover-effect" /> -->

        <!-- <el-tooltip content="源码地址" effect="dark" placement="bottom">
          <ruo-yi-git id="ruoyi-git" class="right-menu-item hover-effect" />
        </el-tooltip> -->

        <!-- <el-tooltip content="文档地址" effect="dark" placement="bottom">
          <ruo-yi-doc id="ruoyi-doc" class="right-menu-item hover-effect" />
        </el-tooltip> -->

        <!-- <screenfull id="screenfull" class="right-menu-item hover-effect" /> -->

        <!-- <el-tooltip content="布局大小" effect="dark" placement="bottom">
          <size-select id="size-select" class="right-menu-item hover-effect" />
        </el-tooltip> -->

      </template>

      <!-- <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">
        <div class="avatar-wrapper">
          <img :src="avatar" class="user-avatar">
          <span v-if="nickname" class="user-nickname">{{ nickname }}</span>
          <i class="el-icon-caret-bottom" />
        </div>
        <el-dropdown-menu slot="dropdown">
          <router-link to="/user/profile">
            <el-dropdown-item>个人中心</el-dropdown-item>
          </router-link>
          <el-dropdown-item @click.native="setting = true">
            <span>布局设置</span>
          </el-dropdown-item>
          <el-dropdown-item divided @click.native="logout">
            <span>修改密码</span>
          </el-dropdown-item>

          <el-dropdown-item divided @click.native="logout">
            <span>退出登录</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown> -->

        <el-dropdown @command="editPassword" >
            <span>
            <i class="el-icon-user-solid" style="margin-right: 5px"></i>
            <span v-if="myComputerShow" >{{ nickname }}</span>
            </span>
            <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="editPassword">{{$t('user.changePassword')}}</el-dropdown-item>
            <el-dropdown-item command="cancellation">{{$t('user.accountCancellation')}}</el-dropdown-item>
            <el-dropdown-item command="loginout">{{$t('user.logout')}}</el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>


        <el-dropdown @command="changeLanguage" >
            <span class="el-dropdown-link" style="margin-left:20px;margin-right:30px;">
                <iconSvg ></iconSvg>
                <span v-if="myComputerShow" >{{$t('language.language')}}</span>
            </span>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="english" >{{$t('language.english')}}</el-dropdown-item>
                <el-dropdown-item command='chinese' divided>{{$t('language.chinese')}}</el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>


        
    </div>


      <el-dialog :title="$t('user.changePassword')" width="450px" :visible.sync="editPwdDialog" :close-on-click-modal="false">
        <el-form :model="editPwd" class="form" ref="editPwdForm" :rules="rules">
          <el-form-item :label="$t('user.currentPassword')" prop="email">
            <el-input type="password" v-model="editPwd.email" :placeholder="$t('user.pleaseEnterCurrentPassword')"></el-input>
          </el-form-item>
          <el-form-item :label="$t('user.newPassword')" prop="password">
            <el-input type="password" v-model="editPwd.password" :placeholder="$t('user.pleaseEnterNewPassword')"></el-input>
          </el-form-item>
          <el-form-item :label="$t('user.confirmNewPassword')" prop="code">
            <el-input type="password"  v-model="editPwd.code" :placeholder="$t('user.reEnterYourNewPassword')"></el-input>
          </el-form-item>
        </el-form>
        <div class="btnBox">
          <el-button type="primary" @click="confirmEditPwd">{{$t('components.confirm')}}</el-button>
          <el-button type="info" @click="editPwdDialog = !editPwdDialog">{{$t('components.cancel')}}</el-button>
        </div>
      </el-dialog>

  </div>

  
</template>

<script>
import { mapGetters } from 'vuex'
//y230525 change direction
import Breadcrumb from '@/components/components/Breadcrumb'
import TopNav from '@/components/components/TopNav'
import Hamburger from '@/components/components/Hamburger'
// import Screenfull from '@/components/components/Screenfull'
// import SizeSelect from '@/components/components/SizeSelect'
// import Search from '@/components/components/HeaderSearch'
// import RuoYiGit from '@/components/components/RuoYi/Git'
// import RuoYiDoc from '@/components/components/RuoYi/Doc'
// import NotifyMessage from '@/layout/components/Message'
import {getPath} from "@/utils/ruoyi";

//york 2023.2.24
import iconSvg from '@/common/iconSvg'

export default {
  components: {
    Breadcrumb,
    TopNav,
    Hamburger,
    //y230604
    iconSvg,
    // Screenfull,
    // SizeSelect,
    // Search,
    // RuoYiGit,
    // RuoYiDoc,
    // NotifyMessage
  },
  data() {
      var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error(this.$t('user.PleasereEnterYourNewPassword')));
        } else if (value !== this.editPwd.password) {
          callback(new Error(this.$t('user.twoPasswordNotMatch')));
        } else {
          callback();
        }
      };
      return {
        editPwdDialog:false,
        editPwd:{
          email:"",
          password:"",
          code:""
        },
        rules: {
          email: [
            { required: true, message: this.$t('user.pleaseEnterCurrentPassword'), trigger: 'blur' }
          ],
          password: [
            { required: true, message: this.$t('user.pleaseEnterNewPassword'), trigger: 'blur' }
          ],
          code: [
            // { required: true, message: this.$t('user.pleaseEnterVerificationCode'), trigger: 'blur' }
            { validator: validatePass2, trigger: 'blur' }
          ]
        },
        tip:this.$t('user.sendVerificationCode'),
        codeTime:true,
        accountName:"",
        tenantType:"",
        //y230704 针对屏幕大小，调整文字的显示
        myComputerShow:true,
        myScreenWidth:0
      }
    },
  computed: {
    ...mapGetters([
      'sidebar',
      'avatar',
      'nickname',
      'device'
    ]),
    setting: {
      get() {
        return this.$store.state.settings.showSettings
      },
      set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'showSettings',
          value: val
        })
      }
    },
    topNav: {
      get() {
        return this.$store.state.settings.topNav
      }
    }
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    },
    async logout() {
      this.$modal.confirm('确定注销并退出系统吗？', '提示').then(() => {
        this.$store.dispatch('LogOut').then(() => {
          location.href = getPath('/index');
        })
      }).catch(() => {});
    },
    //york,23/02/28
    changeLanguage(val){
        let popMessage = this.$t('language.changeLanguage')
        if(val == "english"){
            localStorage.setItem("languageSet", 'en-US');
            this.$i18n.locale = 'en-US'
            this.$message.success(popMessage)
        }else if(val == "chinese"){
            localStorage.setItem("languageSet", 'zh-CN');
            this.$i18n.locale = 'zh-CN'
            this.$message.success(popMessage)
        }
        // document.title = 'setTile'
        // 为了重新实例化vue-router对象，避免一些bug
        location.reload() 
    },
    editPassword(val){
        if(val == "editPassword"){
          this.editPwdDialog = true
        }else if(val == "loginout"){
            //y230629
        //   this.$removeToken()
        //   this.$router.replace({path:"/login"})
            this.$store.dispatch('LogOut').then(() => {
                location.href = '/#/login';
                //y230704 增加重载操作，清除原有残留页面信息
                location.reload();
            }).catch(logouterr => { 
                location.href = '/#/login';
                //y230704 增加重载操作，清除原有残留页面信息
                location.reload();
            })
        }else if(val == "cancellation"){
            this.$modal.confirmIntl(this.$t('user.accountCancellationConfirm'),this.$t('tools.tips'),
                this.$t('tools.confirm'),this.$t('tools.cancel')).then( () => {
                        //y240417 如果为管理员或者测试账号 则不允许删除
                        this.$store.dispatch('LogOut').then(() => {
                            location.href = '/#/login';
                            //y230704 增加重载操作，清除原有残留页面信息
                            location.reload();
                        }).catch(logouterr => { 
                            location.href = '/#/login';
                            //y230704 增加重载操作，清除原有残留页面信息
                            location.reload();
                        })
            }).catch(() => {});
        }
    },
    logoutHandler() {
      this.$modal.confirm('Do you want logout?', 'Tips').then(
        async () =>{    
        // this.$store.dispatch('LogOut').then(() => {
        //   location.href = getPath('/index');
        // })
          this.$removeToken()
          this.$router.replace({path:"/login"})
      }).catch(() => {});
    },
    confirmEditPwd(){
        this.$refs.editPwdForm.validate((vaild) => {
            if (vaild) {
            // this.$post("auth/reset/password",this.editPwd).then(()=>{
                let editPasswordsPost= {};
                editPasswordsPost.username = this.$getUsername()
                editPasswordsPost.oldPassword = this.editPwd.email;
                editPasswordsPost.newPassword = this.editPwd.password;
                let objPost = JSON.parse(JSON.stringify(editPasswordsPost))

                this.$post("auth/update/password",objPost).then(() =>{
                    this.$tip("success",this.$t('user.changedSuccessfully'))
                    this.$removeToken();
                    this.$router.push({path:"/login"})
                })
            } else {
                return false
            }
        })
    }
  },
  watch:{
    editPwdDialog:function(val){
    if(!val){
        this.editPwd = {
        email:"",
        password:"",
        code:""
        }
        this.$refs.editPwdForm.resetFields()
    }
    },
    //y230704
    myScreenWidth:{
        // immediate:true,
        handler(newVal,oldVal) {
            this.myScreenWidth = newVal;
            if (this.myScreenWidth < 768) {
                // 宽度小于768,768宽度为ipad宽度， 当宽度小于这个值时，可以识别为手机
                this.myComputerShow = false;
            } else {
                // 其余尺寸
                this.myComputerShow = true;
            }
        }
    }

  },
  mounted(){
            //加入重载监听函数
            window.onresize = () => (() => {
                this.myScreenWidth = document.body.clientWidth;
            })();
            //
            this.myScreenWidth = document.body.clientWidth;
            if (this.myScreenWidth < 768) {
                // 宽度小于768,768宽度为ipad宽度， 当宽度小于这个值时，可以识别为手机
                this.myComputerShow = false;
            } else {
                // 其余尺寸
                this.myComputerShow = true;
            }
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0,21,41,.08);

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background .3s;
    -webkit-tap-highlight-color:transparent;

    &:hover {
      background: rgba(0, 0, 0, .025)
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .topmenu-container {
    position: absolute;
    left: 50px;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background .3s;

        &:hover {
          background: rgba(0, 0, 0, .025)
        }
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 35px;
          height: 35px;
          border-radius: 50%;
        }
        .user-nickname{
          margin-left: 5px;
          font-size: 14px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
