import Vue from 'vue'
import Router from 'vue-router'
// import { getToken } from '@/util/auth'
//y230603
// import layout from '@/components/layout/index'
import layout from '@/layout/index'
// import message from '@/components/message/index'
// import hardware from '@/components/hardware/index'
// import app from '@/components/app/index'
// import user from '@/components/user/index'
// york230504 加载字典和权限部分内容
// import store from '@/store'



//y230604
//vue - router重写push方法, 解决相同路径跳转报错问题
const originalPush = Router.prototype.push

Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

/**
 * Note: 路由配置项
 *
 * hidden: true                   // 【重要】当设置 true 的时候该路由不会再侧边栏出现 如 401，login 等页面，或者如一些编辑页面 /edit/1
 * alwaysShow: true               // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
 *                                // 只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
 *                                // 若你想不管路由下面的 children 声明的个数都显示你的根路由
 *                                // 你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
 * path: '/login',                // 【重要】访问的 URL 路径
 * component: Layout,             // 【重要】对应的组件；也可以是 (resolve) => require(['@/views/login'], resolve),
 * redirect: noRedirect           // 当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'             // 【重要】设定路由的名字，一定要填写不然使用 <keep-alive> 时会出现各种问题
 * meta : {
    noCache: true                // 【重要】如果设置为 true，则不会被 <keep-alive> 缓存(默认 false)
    title: 'title'               // 【重要】设置该路由在侧边栏和面包屑中展示的名字
    icon: 'svg-name'             // 【重要】设置该路由的图标，对应路径 src/assets/icons/svg
    breadcrumb: false            // 如果设置为 false，则不会在 breadcrumb 面包屑中显示
    activeMenu: '/system/user'   // 当路由设置了该属性，则会高亮相对应的侧边栏。
  }
 */

//y230522 modify variable
// const routes = [
export const constantRoutes = [

    //   {
    //     path: '/redirect',
    //     component: Layout,
    //     hidden: true,
    //     children: [
    //       {
    //         path: '/redirect/:path(.*)',
    //         component: (resolve) => require(['@/views/redirect'], resolve)
    //       }
    //     ]
    //   },
    //   {
    //     path: '/404',
    //     component: (resolve) => require(['@/views/error/404'], resolve),
    //     hidden: true
    //   },
    //   {
    //     path: '/401',
    //     component: (resolve) => require(['@/views/error/401'], resolve),
    //     hidden: true
    //   },
    //   {
    //     path: '',
    //     component: Layout,
    //     redirect: 'index',
    //     children: [{
    //         path: 'index',
    //         component: (resolve) => require(['@/views/index'], resolve),
    //         name: '首页',
    //         meta: {title: '首页', icon: 'dashboard', affix: true}
    //       }
    //     ]
    //   }

    //y230626
    {
        path: '/redirect',
        component: layout,
        hidden: true,
        children: [
            {
            path: '/redirect/:path(.*)',
            component: (resolve) => require(['@/views/redirect'], resolve)
            }
        ]
    },
    {
        path: '/404',
        component: (resolve) => require(['@/views/error/404'], resolve),
        hidden: true
    },
    // {
    //     path: '/mesh',  component: layout, name: "mesh", meta: { title: "menu.meshManagement" }, icon: "el-icon-s-grid",
    //     children: [
    //         { path: "/meshManage", component: () => import('@/components/mesh/meshManage'), name: "meshManage", meta: { title: "menu.meshManagement" } }
    //     ]
    // },
    {
        path: '/', component: layout, name: "message", meta: { title: "menu.workbench" }, hidden: true, icon: "el-icon-s-comment",
        children: [
            { path: "/messageList", component: () => import('@/components/message/index'), name: "index", meta: { title: "menu.feedback" } },
            { path: "/hardwareList", component: () => import('@/components/hardware/index'), name: "hardwareListHide", meta: { title: "menu.firmwareManagement" } },
            { path: "/appList", component: () => import('@/components/app/index'), name: "appListHide", meta: { title: "menu.appsManagement" } },
            // 此隐藏菜单不可缺少，影响页面左边菜单的显示 y240704
            { path: "/storeHeatmap", component: () => import('@/components/statistics/heatmap'), name: "storeHeatmapHide", meta: { title: "menu.storeHeatmap" } },
            { path: "/customerCounting", component: () => import('@/components/people/storePeople'), name: "customerCountingHide", meta: { title: "menu.peopleCounting" } },

            { path: "/meshManage", component: () => import('@/components/mesh/meshManage'), name: "meshManageHide", meta: { title: "menu.meshManagement" } }
        ]
    },
    {
        path: "/userManual", component: () => import("@/components/help/manual"), name: "manual", meta: { title: "menu.login" }, hidden: true
    },
    {
        path: "/enmanual", component: () => import("@/components/help/enmanual"), name: "enmanual", meta: { title: "menu.login" }, hidden: true
    },
    {
        path: "/userManualVideo", component: () => import("@/components/help/videoComponent"), name: "video", meta: { title: "menu.login" }, hidden: true
    },
    {
        path: "/privacy", component: () => import("@/components/privacy/privacy"), name: "privacy", meta: { title: "menu.login" }, hidden: true
    },
    {
        path: "/login", component: () => import("@/components/login/login"), name: "login", meta: { title:"menu.login"},hidden:true
    },
    {
        path: "/download",  component: () => import("@/components/download/index"), name: "download", meta: { title:"menu.appDownload"},hidden:true
    }
    // {
    //     path: '/mesh',  component: layout, name: "mesh", meta: { title: "menu.meshManagement" }, icon: "el-icon-s-grid",
    //     children: [
    //         { path: "/meshManage", component: () => import('@/components/mesh/meshManage'), name: "meshManage", meta: { title: "menu.meshManagement" } },
    //         { path: "/meshUser", alias: '/meshUser', component: () => import('@/components/mesh/index'), name: "meshUser", meta: { title: "menu.meshUserManagement" } }
    //     ]
    // },
    // {
    //     path: '/device', component: layout, name: "assets", meta: { title: "menu.deviceManagement" }, icon: "el-icon-s-help",
    //     children: [
    //         { path: "/lightList", component: () => import('@/components/device/light'), name: "lightList", meta: { title: "menu.luminaire" } },
    //         { path: "/mobileList", component: () => import('@/components/device/mobile'), name: "mobileList", meta: { title: "menu.mobileDevice" } }
    //     ]
    // },
    // {
    //     path: '/hardware', component: layout, name: "hardware", meta: { title: "menu.firmwareManagement" }, icon: "el-icon-cpu",
    //     children: [
    //         { path: "/hardwareList", component: () => import('@/components/hardware/index'), name: "hardwareList", meta: { title: "menu.firmwareManagement" } }
    //     ]
    // },
    // {
    //     path: '/apps', component: layout, name: "apps", meta: { title:"menu.appsManagement"},icon:"el-icon-mobile",
    //   children:[
    //       { path: "/appList", component: () => import('@/components/app/index'), name: "appList", meta: { title:"menu.appsManagement"}}
    //   ]
    // },
    // {
    //     path: '/statistics', component: layout, name: "statistics", meta: { title: "menu.statisticsManagement" }, icon: "el-icon-s-marketing",
    //     children: [
    //         { path: "/customerFlow", component: () => import('@/components/statistics/customer'), name: "customerFlow", meta: { title: "menu.customerStatistics" } },
    //         { path: "/storePopularity", component: () => import('@/components/statistics/storepopularity'), name: "storePopularity", meta: { title: "menu.storePopularity" } },
    //     ]
    // },
    // {
    //     path: '/users', component: layout, name: "users", meta: { title:"menu.userCenter"},icon:"el-icon-user",
    //     children: [
    //       { path: "/agentList", component: () => import('@/components/user/agent'), name: "agentList", meta: { title: "menu.agentManagement" } },
    //       { path: "/agentPackageList", component: () => import('@/components/user/agentPackage'), name: "agentPackage", meta: { title: "menu.agentPackage" } },
    //       { path: "/staffList", component: () => import('@/components/user/staff'), name: "staffList", meta: { title: "menu.staffManagement" } },
    //       { path: "/menuList", component: () => import('@/components/user/menu'), name: "menuList", meta: { title: "menu.menuManagement" } },
    //       { path: "/roleList", component: () => import('@/components/user/role'), name: "roleList", meta: { title: "menu.roleManagement" } },
    //       { path: "/deptList", component: () => import('@/components/user/dept'), name: "deptList", meta: { title: "menu.deptManagement" } },
    //       { path: "/postList", component: () => import('@/components/user/post'), name: "postList", meta: { title: "menu.postManagement" } }
    //   ]
    // },
    // {
    //     path: '/message', component: layout, name: "messages", meta: { title: "menu.feedback" }, icon: "el-icon-s-comment",
    //     children: [
    //         { path: "/messageList", component: () => import('@/components/message/index'), name: "messageList", meta: { title: "menu.feedback" } },
    //     ]
    // },
    // {
    //     path: '/kailinks', component: layout, name: "kailinks", meta: { title: "menu.kailinks" }, icon: "el-icon-s-comment",
    //     children: [
    //         { path: "/rkproductList", component: () => import('@/components/kailinks/rkproduct'), name: "rkproductList", meta: { title: "menu.rkproductManagement" } },
    //         { path: "/rkdeviceList", component: () => import('@/components/kailinks/rkdevice'), name: "rkdeviceList", meta: { title: "menu.rkdeviceManagement" } }
    //     ]
    // },
    // {
    //     path: '/settings', component: layout, name: "settings", meta: { title: "menu.platformSettings" }, icon: "el-icon-setting",
    //     children: [
    //         { path: "/dict", component: () => import('@/components/settings/dict'), name: "parameterSettings", meta: { title: "menu.dictManagement" } },
    //         { path: "/areaManagement", component: () => import('@/components/settings/area'), name: "areaManagement", meta: { title: "menu.areaManagement" } },
    //         { path: "/shipmentCalibration", component: () => import('@/components/settings/shipmentCalibration'), name: "shipmentCalibration", meta: { title: "menu.shipmentCalibration" } },
    //         { path: "/userAdvancedSettings", component: () => import('@/components/settings/userAdvancedSettings'), name: "userAdvancedSettings", meta: { title: "menu.userAdvancedSettings" } }
    //     ]
    // }
  ]

let router = new Router({
    mode: "hash",
    //y230522, modify variable
    // routes
    routes: constantRoutes
})
// const whiteList = ['/login','/download']
// router.beforeEach(async(to,from,next)=>{
//     const hasToken = getToken()

    

//     if (hasToken && hasToken !== 'undefined') {
//         if (to.path === '/') {
//             // if is logged in, redirect to the home page
//             next({ path: '/messageList' })
//             // next({ path: '/index' }) //y230404
//         }
//         // next()
//         //在进行登录页面跳转之前，先加载权限和字典数据，york230504  
//         // to.meta.title && store.dispatch('settings/setTitle', to.meta.title)
//         if (store.getters.roles.length === 0) {
//             // isRelogin.show = true
//             // 获取字典数据 add by york230504
//             store.dispatch('dict/loadDictDatas')
//             // 判断当前用户是否已拉取完 user_info 信息
//             // store.dispatch('GetInfo').then(() => {
//             //     // isRelogin.show = false
//             //     store.dispatch('GenerateRoutes').then(accessRoutes => {
//             //         // 根据 roles 权限生成可访问的路由表
//             //         // router.addRoutes(accessRoutes) // 动态添加可访问路由表
//             //         // next({ ...to, replace: true }) // hack方法 确保addRoutes已完成
//             //         next()
//             //     })
//             // }).catch(err => {
//             //     store.dispatch('LogOut').then(() => {
//             //         // Message.error(err)
//             //         // next({ path: '/' })
//             //         next(`/login?redirect=${to.path}`)
//             //     })
//             // })
//         }
//         // } else {
//         //     next()
//         // }
//         next()
//     } else {
//         //如果未登录，则行白名单放行检查，否则直接跳转到登录页面。york230504
//         if (whiteList.indexOf(to.path) !== -1) {
//             next()
//         } else {
//             next(`/login?redirect=${to.path}`)
//         }
//     }
// })

// router.afterEach((to, from) => {
//   if (!to.name) {
//     router.replace('/404')
//   }
// })

export default router
Vue.use(Router)